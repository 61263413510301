.footer {
    padding: 80px 0 0 0;
}

.footer_first {
    display: grid;
    grid-template-columns: 3fr 1fr 1fr;
    margin-bottom: 38px;
}
.footer_first__logo {
    margin: 0 0 38px 0;
}
.footer_first__logo img,
.footer_first__logo svg {
    display: block;
    width: auto;
    height: 49px;
}

.footer_menu__header {
    font-weight: 700;
    font-size: 14px;
    line-height: 1.857em;
    text-transform: uppercase;
}
.footer_menu__list {
    list-style: none;
    line-height: 1.875em;
}
.footer_menu__list a {
    color: var(--darkgrey);
}
.footer_menu__list a:hover {
    color: var(--maincolor);
}

.footer_second {
    padding: 20px 0;
    font-size: 14px;
    line-height: 1.357;
    border-top: 1px solid #d9d9d9;
}
.footer_second__wrapper {
    display: grid;
    grid-template-columns: 140px 1fr;
    padding-right: 140px;
}
.footer_second a {
    color: var(--darkgrey);
}
.footer_second a:hover {
    color: var(--maincolor);
}
.footer_second__social {
    display: flex;
    margin-top: 40px;
}
.footer_second__social a {
    margin-right: 20px;
}
.footer_second__copyright {
    text-align: center;
}

.footer .login_form__lang_selector {
    margin-bottom: 40px;
}