.container {
    background: linear-gradient(101.42deg, var(--ligthmain) -0.04%, rgba(255, 252, 252, 0) 94.2%);
    box-sizing: border-box;
    overflow: hidden;
    width: 100%;
    position: relative;
    height: 300px;
}
.container::before {
    content: '';
    display: block;
    position: absolute;
    left: -150px;
    top: 0;
    height: 100%;
    width: 150px;
    background: linear-gradient(to right, transparent 0%, var(--ligthmain) 50%, transparent 100%);
    animation: load 3s cubic-bezier(0.4, 0.0, 0.2, 1) infinite;
}

@keyframes load {
    from {
        left: -150px;
    }
    to {
        left: 100%;
       }
}

.containerLoaded::before {
    animation: none;
}
.containerLoaded {
    background: inherit;
}

.image {
    box-shadow: 0 0 20px rgba(0,0,0,.15);
    opacity: 0;
    width: 100%;
    object-fit: cover;
    transition: opacity .3s ease-in-out;
}

.imageLoaded {
    opacity: 1;
}