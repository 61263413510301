@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,400;0,600;0,700;1,400;1,600;1,700&display=swap');


*, *::before, *::after {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    scroll-margin-top: 60px;
}

.preload * {
	-webkit-transition: none !important;
	-moz-transition: none !important;
	-ms-transition: none !important;
	-o-transition: none !important;
	transition: none !important;
}

:root {
    --lineheight: 1.362em;
    --green: #04FF84;
    --lightgrey: #f9f9f9;
    --ligthmain: #fceaea;
    --maincolor: #9F0027;
    --monolithgreen: #006834;
    --monolithlightgreen: #00B707;
    --monolithlightyellow: #FFD600;
    --darkgrey: #606060;
    --grey: #888;
    --orange: #FC6738;
    --maxwidth: 1600px;
    --gap: 20px;
    --transition: all .5s cubic-bezier(0.54,0.22,0.22,0.97);
}

html {
	scroll-behavior: smooth;
}

body {
    font-family: 'Open Sans', sans-serif;
    font-size: 16px;
    line-height: 1.625em;
    color: var(--darkgrey);
    background: #FFF;
}

input, textarea, button, select {
    font-family: 'Open Sans', sans-serif;
    font-size: 1em;
    accent-color: var(--maincolor);
}
button {
    cursor: pointer;
    background: transparent;
    border: none;
    vertical-align: top;
    white-space: nowrap;
}
button:disabled svg path{
    stroke: #9e9e9e;
}
button:disabled{
    cursor: default;
}

strong, .strong, b, .b {
    font-weight: 700;
}
img, svg {
    vertical-align: top;
}
svg {
    pointer-events: none;
}
hr {
    border: 0 none;
    height: 1px;
    background: #d9d9d9;
    margin: 23px 0;
}

.wrapper {
    max-width: var(--maxwidth);
    margin: 0 auto;
    padding: 0 var(--gap);
}

.center {
    text-align: center;
}

#root {
    min-height: 100vh;
}

/* Typography */
h1, .h1 {
    display: block;
    font-size: 24px;
    line-height: var(--lineheight);
    padding: 0 0 1em 0;
    margin-top: 1rem;
}
h1::first-letter, .h1::first-letter,
h2::first-letter, .h2::first-letter,
h3::first-letter, .h3::first-letter {
    text-transform: uppercase;
}
h2, .h2 {
    font-size: 20px;
    line-height: var(--lineheight);
    padding: 0 0 1em 0;
}
h3, .h3 {
    font-size: 20px;
    line-height: 1.362em;
    padding: 0 0 .5em 0;
}
h4, .h4 {
    padding: 0 0 .5em 0;
}
p {
    padding: 0 0 1em 0;
}
ol {
    padding: 0 0 1em 0;
}
ol li {
    margin: 0 0 0 1em;
    padding: 0 0 0 .5em;
}
a {
    text-decoration: none;
}
/* Typography */

/* classes */
.hidden {
    display: none;
}
.visibilityhidden {
    visibility: hidden;
}
.inline-block {
    display: inline-block;
}
.notvalid {
    border-color: var(--maincolor) !important;
}
.width100 {
    width: 100%;
}
.width50 {
    width: 50%;
}
.width25 {
    width: 25%;
}
.width75 {
    width: 75%;
}
h1 svg {
    position: relative;
    top: 5px;
}
.red {
    color: var(--maincolor);
}
.green {
    color: var(--monolithgreen);
}
.redbg {
    color: #FFF;
    background: var(--maincolor) !important;
}
.greybg {
    background: #d9d9d9 !important;
}
.left {
    text-align: left;
}
.center {
    text-align: center;
}
.right {
    text-align: right;
}
.inrightside {
    justify-content: end;
}
.relative {
    position: relative;
}
.paddingbottom40 {
    padding-bottom: 40px;
}
.paddingbottom30 {
    padding-bottom: 30px;
}
.nomargin {
    margin: 0 !important;
}
.top0 {
    top: 0 !important;
}
.marginbottom15 {
    margin-bottom: 15px;
}
.marginbottom20 {
    margin-bottom: 20px;
}
.marginbottom30 {
    margin-bottom: 30px;
}
.marginbottom40 {
    margin-bottom: 40px;
}
.marginleft10 {
    margin-left: 10px;
}
.marginleft30 {
    margin-left: 30px;
}
.nowrap {
    white-space: nowrap;
}
.top9 {
    position: relative;
    top: 9px;
}
.custom_button_hover {
    transition:  0.6s ease 0s;
}
.custom_button_hover:hover {
    background: var(--maincolor);
}
/* classes */

/* local bootstrap */
.btn {
    display: inline-block;
    cursor: pointer;
    line-height: 3.125em;
    background: #999;
    color: #FFF;
    border-radius: 5px;
    white-space: nowrap;
    font-weight: 700;
    padding: 0 2em;
    transition: var(--transition);
    text-align: center;
}
.btn-info {
    background: var(--darkgrey);
}
.btn-primary {
    background: var(--maincolor);
}
.btn-green {
    background: #00B707;
}
.btn-outline {
    background: transparent;
    color: var(--darkgrey);
    border: 1px solid;
}
.btn:hover, .btn-info:hover {
    background: var(--maincolor);
}
.btn.btn-primary:hover {
    background: var(--darkgrey);
}
.btn.btn-outline:hover {
    background: transparent;
    color: var(--maincolor);
}
.btn-outline svg path {
    stroke: var(--darkgrey);
    transition: var(--transition);
}
.btn-outline:hover svg path {
    stroke: var(--maincolor);
}
.btn-block {
    display: block;
    width: 100%;
    padding: 0 1em;
}
.btn-contacts {
    position: relative;
    min-width: 150px;
    padding: 0 2em 0 .75em;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    text-align: left;
}
.btn svg {
    position: relative;
    top: 1em;
    margin-left: .5em;
}
.btn-contacts svg {
    position: absolute;
    top: 16px;
    right: .75em;
}
.btn svg.oneeighty {
    transform: rotate(180deg);
}
.form-control {
    width: 100%;
    line-height: 3em;
    border: 1px solid #d9d9d9;
    border-radius: 5px;
    color: var(--darkgrey);
    padding: 0 2em;
    background-color: #FFF;
}
.form-control-little {
    line-height: 2.375em;
    padding: 0 1.5em;
}
.btn:disabled {
    pointer-events: none;
    cursor: not-allowed;
}
.form-control:disabled,
.form-control.disabled {
    cursor: not-allowed;
    background: #f8f8f8;
    pointer-events: none;
}
.form-control[type="search"] {
    background-position: center right 17px;
    background-repeat: no-repeat;
    padding-right: 3em;
    background-image: url("data:image/svg+xml,%3Csvg width='21' height='19' viewBox='0 0 21 19' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M9.20115 17.1666C13.6194 17.1666 17.2011 13.5849 17.2011 9.16663C17.2011 4.74835 13.6194 1.16663 9.20115 1.16663C4.78288 1.16663 1.20117 4.74835 1.20117 9.16663C1.20117 13.5849 4.78288 17.1666 9.20115 17.1666Z' stroke='%23606060' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M20.2011 18.1667L15.8511 14.3' stroke='%23606060' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
}
.nomagnify.form-control[type="search"] {
    background-image: none;
}
select.form-control {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background-image: url("data:image/svg+xml,%3Csvg fill='none' height='18' viewBox='0 0 18 18' width='18' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='m4.5 6.75 4.5 4.5 4.5-4.5' stroke='%239f0027' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.5'/%3E%3C/svg%3E");
    background-position: center right 14px;
    background-repeat: no-repeat;
}
textarea.form-control {
    height: 240px;
    line-height: 1.362em;
    padding-top: 14px;
    padding-bottom: 14px;
}
.wrapprePhone div .form-control{
    height: 50px !important;
    width: 100%;
}

.table {
    width: 100%;
    border-collapse: collapse;
    text-align: center;
}
.table thead {
    background: #d9d9d9;
    font-weight: 700;
    line-height: 50px;
    white-space: nowrap;
}
.table tr:nth-child(even) {
    background: #f2f2f2;
}
.table td {
    padding: 12px 0;
}
.table td a {
    color: var(--darkgrey);
    text-decoration: underline;
}
.table td a:hover {
    color: var(--maincolor);
}
.table_button {
    position: relative;
    top: 4px;
}
.table_button svg {
    pointer-events: none;
}

.alert {
    font-size: .875rem;
    /* line-height: 40px; */
    line-height: 20px;
    color: #FFF;
    background: var(--darkgrey);
    border-radius: 5px;
    font-weight: 700;
    /* padding: 0 13px; */
    padding: 10px 13px 10px 41px;
    margin-bottom: 1rem;
    background-position: top 11px left 13px;
    background-repeat: no-repeat;
    background-image: url("data:image/svg+xml,%3Csvg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M9 16.5C13.1421 16.5 16.5 13.1421 16.5 9C16.5 4.85786 13.1421 1.5 9 1.5C4.85786 1.5 1.5 4.85786 1.5 9C1.5 13.1421 4.85786 16.5 9 16.5Z' stroke='white' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M9 12V9' stroke='white' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M9 6H9.0075' stroke='white' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
}
.alert.alert_pending {
    background-color: var(--orange);
}
.alert.alert_resolved {
    background-color: #00B707;
}
.alert.alert_danger {
    background-color: var(--maincolor);
}
.alert svg {
    position: relative;
    top: 11px;
    margin-right: 10px;
}
/* local bootstrap */

.top_navigation {
    position: fixed;
    right: 0;
    bottom: 0;
    background: #000;
    color: #CCC;
    line-height: 40px;
    padding: 0 10px;
    z-index: 1;
}
.top_navigation a {
    color: #FFF;
}
.top_navigation a:hover {
    color: yellow;
}

/* loader */
.loader {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    height: 10px;
    background: linear-gradient(-45deg, #ee7752, #e73c7e, #23a6d5, #23d5ab);
	background-size: 400% 400%;
	animation: gradient 3s ease infinite;
    z-index: 1000;
    transition: var(--transition);
    transform: translateY(-10px);
}
.loader.active {
    transform: translateY(0);
}
@keyframes gradient {
	0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
}
/* loader */

/* content placeholder */
.content_placeholder {
    height: 1.5rem;
    position: relative;
    background: #f2f2f2;
    border-radius: 3px;
    overflow: hidden;
}
.content_placeholder::after {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    animation: content-placeholder-animation 1s linear infinite;
    background: linear-gradient(to right, rgba(15, 15, 15, 0) 0%, rgba(219, 219, 219, 0.4) 50%, rgba(255, 255, 255, 0) 100%);
}
.table_loader {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    gap: 20px;
}
.table_loader .content_placeholder, .content_placeholder.content_placeholder__with_table {
    height: 3em;
    margin-bottom: 20px;
}
@keyframes content-placeholder-animation {
    0% {
      transform: translateX(-50%);
    }
    100% {
      transform: translateX(300%);
    }
}
.list .placeholder_grid,
.grid .placeholder_list
{
    display: none;
}
.productlist_placeholder {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 20px;
}
.productlist_placeholder__horizontal__unit {
    position: relative;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 40px;
    padding: 20px;
    border: 1px solid #d9d9d9;
    border-radius: 5px;
    margin-bottom: 20px;
}
.productlist_placeholder__horizontal__unit::before,
.productlist_placeholder__horizontal__unit::after {
    content: '';
    position: absolute;
    top: 20px;
    bottom: 20px;
    left: 33%;
    width: 1px;
    background: #d9d9d9;
}
.productlist_placeholder__horizontal__unit::after {
    left: auto;
    right: 33%;
}
.productlist_placeholder__horizontal__unit__image {
    width: 50%;
    height: 200px;
    margin: 0 auto 20px auto;
}
.productlist_placeholder__horizontal__unit__rating {
    width: 40%;
    height: 20px;
    margin: 0 auto;
}
.productlist_placeholder__horizontal__unit__prodname {
    height: 40px;
    margin-bottom: 20px;
}
.productlist_placeholder__horizontal__unit__data {
    width: 50%;
    margin-bottom: 20px;
}
.productlist_placeholder__horizontal__unit__table {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 20px;
    margin-bottom: 40px;
}
.productlist_placeholder__horizontal__unit__pricecart {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
}
.productlist_placeholder__horizontal__unit__pricecart .content_placeholder {
    height: 40px;
}
.productlist_placeholder__unit {
    position: relative;
    padding: 20px;
    border: 1px solid #d9d9d9;
    border-radius: 5px;
}
.productlist_placeholder__unit__title {
    margin: 0 auto 20px auto;
    height: 3rem;
}
.productlist_placeholder__unit__image {
    width: 200px;
    height: 200px;
    margin: 0 auto 20px auto;
}
.productlist_placeholder__unit__columns {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
}
.productlist_placeholder__unit__columns>* {
    height: 2rem;
}
@media screen and (max-width: 1439px) {
    .productlist_placeholder {
        grid-template-columns: repeat(3, 1fr);
    }
}
/* content placeholder */

/* Online Chat fix */
#si-wrapper .silc-btn {
    bottom: 12px !important;
    right: 30px !important;
}
/* Online Chat fix */

/* reset storybook padding */
.sb-show-main.sb-main-padded {
    padding: 0;
}
/* reset storybook padding */

.selected_item_in_list {
    background-color: #f786a215 !important;
}

.text_transform_capitalize {
    text-transform: lowercase;
}
.text_transform_capitalize:first-letter {
    text-transform: uppercase;
}



#signalzen_widget__root a {
    width: 70px !important;
    height: 70px !important;
    background: var(--maincolor) !important;
}
#widget__wrapper .d513a {
    background: var(--maincolor) !important;
}

.wrapper_loading__button {
    height: 50px;
    display: flex; 
    justify-content: center;
    align-items: center;
    cursor: default;
}