.main_page__h3 {
    padding: 40px 0;
}

.wide_banner {
    position: relative;
    background: #165723;
    margin-bottom: 40px;
    overflow: hidden;
}
.wide_banner img {
    display: block;
    width: 100%;
    height: auto;
    max-width: var(--maxwidth);
    margin: 0 auto;
}
.wide_banner::before, .wide_banner::after {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    width: 355px;
    left: calc(50% - 800px);
    background: linear-gradient(90deg, #165723 58.03%, rgba(23, 88, 36, 0) 96.45%);
}
.wide_banner::after {
    left: auto;
    right: calc(50% - 800px);
    background: linear-gradient(-90deg, #165723 58.03%, rgba(23, 88, 36, 0) 96.45%);
}

.two_banners {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
    padding: 0px;
    /* margin-bottom: 40px; */
}
.two_banners a {
    display: block;
}
.two_banners img {
    display: block;
    width: 100%;
    height: auto;
    cursor: pointer;
}

.top_banner_preloader {
    height: 450px;
    margin-bottom: 40px;
}
.ais-Hits-list {
    list-style: none;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: var(--gap);
}
@media screen and (max-width:1439px) {
    .ais-Hits-list {
        grid-template-columns: repeat(3, 1fr);
    }
}
@media screen and (max-width:1120px) {
    .ais-Hits-list {
        grid-template-columns: repeat(2, 1fr);
    }
}