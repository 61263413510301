.grid_filter h1 {
    font-size: 1.5rem;
    line-height: 1.362em;
    margin-bottom: 35px;
}
.top_filter_buttons {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    gap: 0 40px;
    margin-bottom: 20px;
}
.top_filter_button {
    position: relative;
    font-size: 1rem;
    line-height: 3em;
    background: #f2f2f2;
    color: #999;
    border: 1px solid #d9d9d9;
    border-radius: 5px;
    transition: var(--transition);
}
.top_filter_button:hover {
    background: #f8f8f8;
}
.top_filter_button[data-title]::after {
    content: attr(data-title);
    position: absolute;
    padding: 0 17px;
    top: -20px;
    right: 0px;
    white-space: nowrap;
    font-size: .875rem;
    line-height: 2em;
    background: #FFF;
    border: 1px solid #d9d9d9;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    border-radius: 5px 5px 0 5px;
    z-index: 1;
    pointer-events: none;
    transition: var(--transition);
    visibility: hidden;
    opacity: 0;
    transform: translateY(5px);
    color: #727272;
}
.top_filter_button[data-title]:hover::after {
    visibility: visible;
    opacity: 1;
    transform: translateY(0);
}
.top_filter_button svg path,
.top_filter_button svg rect,
.top_filter_button svg line {
    stroke: #999;
}
.top_filter_button.active svg path,
.top_filter_button.active svg rect,
.top_filter_button.active svg line {
    stroke: #FFF;
}
.top_filter_button.active {
    color: #FFF;
    background: var(--maincolor);
    border-color: var(--maincolor);
}
.top_filter_button svg {
    vertical-align: text-bottom;
}

.sticky_filter {
    position: sticky;
    padding-top: 10px;
    padding-bottom: 5px;
    top: 55px;
    background: #FFF;
    z-index: 2;
    margin-bottom: 17px;
}
.grid_filter__selected_filters {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 3px;
}
.grid_filter__selected_filters__button,
.ais-CurrentRefinements-category {
    display: inline-block;
    position: relative;
    padding: 0 35px 0 14px;
    margin: 0 10px 7px 0;
    font-size: .875em;
    line-height: 2em;
    color: var(--darkgrey);
    border: 1px solid #d9d9d9;
    background: #f2f2f2;
    border-radius: 5px;
    cursor: pointer;
}
.grid_filter__selected_filters__button[data-count="0"] {
    display: none;
}
.grid_filter__selected_filters__button::before, .grid_filter__selected_filters__button::after {
    content: '';
    position: absolute;
    top: 8px;
    right: 14px;
    width: 1px;
    height: 13px;
    background: var(--darkgrey);
    transform: rotate(45deg);
}
.grid_filter__selected_filters__button::after {
    transform: rotate(-45deg);
}
.grid_filter__selected_filters__button:hover::before, .grid_filter__selected_filters__button:hover::after {
    background: var(--maincolor);
}
.grid_filter__status {
    display: grid;
    grid-template-columns: auto 1fr auto auto;
    gap: 0 33px;
    line-height: 3.125em;
}
.grid_filter__status__label {
    margin: 0 20px;
    white-space: nowrap;
}
.grid_filter__status input[type=checkbox] {
    margin: 0 22px 0 0;
}
.sortby,
.ais-SortBy-select {
    position: relative;
    width: 250px;
    padding: 0 28px;
    line-height: 3em;
    border: 1px solid #d9d9d9;
    border-radius: 5px;
    appearance: none;
    background-color: #FFF;
    background-repeat: no-repeat;
    background-position: center right 23px;
    cursor: pointer;
    margin-left: 20px;
    background-image: url("data:image/svg+xml,%3Csvg width='12' height='6' viewBox='0 0 12 6' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1.5 0.75L6 5.25L10.5 0.75' stroke='%23606060' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
}
.grid_filter__status__view {
    width: 50px;
    height: 50px;
    background: #f2f2f2;
    border: 1px solid #d9d9d9;
    border-radius: 5px;
    margin-left: 6px;
    background-position: center;
    background-repeat: no-repeat;
}
.grid_filter__status__view.active {
    color: #FFF;
    background-color: var(--maincolor);
}
.grid_filter__status__view svg .active_element {
    stroke: #999;
}
.grid_filter__status__view.active svg .active_element {
    stroke: #FFF;
}

@media screen and (max-height: 970px) {
    .grid_filter__status {
        gap: 0 15px;
        line-height: 3.125em;
    }
}

/* aside filters */
.left_filter {
    --headerheight: 55px;
    --headerheightfixedmenu: 95px;
    --headerheightbanner: 55px;
    --headerheightmenu: 205px;
    position: fixed;
    top: var(--headerheightbanner);
    bottom: 0;
    left: 0;
    width: 395px;
    z-index: 20;
    transition: var(--transition);
    transform: translateX(-395px);
    background: #FFF;
}
.banner200closed .left_filter {
    top: var(--headerheightmenu);
}
/* .header_was_fixed .left_filter {
    top: var(--headerheight);
} */
.fixed_menu_showed .left_filter {
    top: var(--headerheightfixedmenu);
}
.left_filter.active {
    transform: translateX(0);
}
.left_filter__inner {
    overflow-y: auto;
    max-height: calc(100vh - var(--headerheightbanner));
}
.banner200closed .left_filter__inner {
    max-height: calc(100vh - var(--headerheight));
}
.header_was_fixed .left_filter__inner {
    max-height: calc(100vh - var(--headerheightfixedmenu));
}
.fixed_menu_showed .left_filter__inner {
    max-height: calc(100vh - var(--headerheightmenu));
}
.show_hide__left_filter {
    position: absolute;
    top: 50%;
    right: -110px;
    width: 190px;
    font-size: .875rem;
    line-height: 30px;
    color: #FFF;
    background: #999;
    border-radius: 5px;
    transform: rotate(-90deg);
}
.banner200closed .show_hide__left_filter,
.header_was_fixed .show_hide__left_filter {
    top: 390px;
}
.show_hide__left_filter svg {
    position: absolute;
    top: 6px;
    right: 11px;
    transform: rotate(90deg);
    transition: var(--transition);
}
.show_hide__left_filter svg.turned {
    transform: rotate(270deg);
}
.show_hide__left_filter__count {
    position: absolute;
    top: 0;
    left: 0;
    width: 30px;
    height: 30px;
    background: var(--maincolor);
    transform: rotate(90deg);
    border-radius: 0 0 5px 5px;
}
.filter_unit {
    position: relative;
    border: 1px solid #d9d9d9;
    padding: 15px 18px;
    margin-bottom: 10px;
    background: #FFF;
    transition: var(--transition);
}
.filter_unit.collapsed {
    max-height: 60px;
    overflow: hidden;
}
.filter_unit__header {
    font-size: 1.25rem;
    line-height: 1.362em;
    font-weight: 700;
    color: var(--maincolor);
    margin-bottom: 20px;
}
.filter_unit__search_wrapper {
    position: relative;
    margin-bottom: 20px;
}
.filter_unit__search {
    width: 100%;
    padding: 0 40px 0 20px;
    font-size: 1rem;
    line-height: 2.375em;
    border: 1px solid #d9d9d9;
    border-radius: 5px;
    background-position: center right 10px;
    background-repeat: no-repeat;
    background-image: url("data:image/svg+xml,%3Csvg width='22' height='20' viewBox='0 0 22 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M9.87449 17.3333C14.2928 17.3333 17.8745 13.7516 17.8745 9.33334C17.8745 4.91507 14.2928 1.33334 9.87449 1.33334C5.45622 1.33334 1.87451 4.91507 1.87451 9.33334C1.87451 13.7516 5.45622 17.3333 9.87449 17.3333Z' stroke='%23606060' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M20.8745 18.3334L16.5245 14.4667' stroke='%23606060' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
}
.filter_unit__list, .ais-RefinementList-list {
    list-style: none;
    max-height: 200px;
    overflow-y: auto;
}
.filter_unit.no_height_limit .filter_unit__list,
.filter_unit.no_height_limit .ais-RefinementList-list {
    max-height: none;
}
.filter_unit li {
    margin-bottom: 5px;
}
.filter_label,
.ais-RefinementList-label {
    position: relative;
    display: block;
    overflow-x: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    padding-right: 20px;
    cursor: pointer;
}
.filter_label.brandschecked.false:not(.brands),
.filter_label[data-count="0"]
{
    pointer-events: none;
    cursor: not-allowed;
    cursor: no-drop;
    opacity: .5;
}
.filter_label:hover,
.ais-RefinementList-label:hover {
    color: var(--maincolor);
}
.filter_checkbox,
.ais-RefinementList-checkbox {
    margin-right: 12px;
}
.filter_alert,
.ais-RefinementList-count {
    position: absolute;
    top: 0px;
    right: 0;
    font-size: 9px;
    width: 24px;
    line-height: 24px;
    text-align: center;
    background: #f2f2f2;
    border-radius: 50%;
}
.filter_remove_selections {
    position: relative;
    color: var(--maincolor);
    background: transparent;
    padding: 0 0 0 25px;
    margin-bottom: 20px;
}
.filter_remove_selections[data-count="0"] {
    display: none;
}
.filter_remove_selections::before,
.filter_remove_selections::after {
    content: '';
    position: absolute;
    top: 4px;
    left: 6px;
    width: 1px;
    height: 16px;
    background: var(--maincolor);
    transform: rotate(45deg);
}
.filter_remove_selections::after {
    transform: rotate(-45deg);
}
.filter_unit__expand_collapse {
    position: absolute;
    top: 28px;
    right: 20px;
    width: 9px;
    height: 9px;
    color: var(--maincolor);
    border-top: 2px solid;
    border-right: 2px solid;
    transform: rotate(-45deg);
    transition: var(--transition);
}
.filter_unit__expand_collapse.turned {
    top: 24px;
    transform: rotate(135deg);
}
.filter_placeholders>* {
    position: relative;
    height: 1.5rem;
    background: #f2f2f2;
    border-radius: 3px;
    margin: 1em 0;
    overflow: hidden;
}
.filter_placeholders>*::after {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    animation: content-placeholder-animation 1s linear infinite;
    background: linear-gradient(to right, rgba(15, 15, 15, 0) 0%, rgba(219, 219, 219, 0.4) 50%, rgba(255, 255, 255, 0) 100%);
}
/* aside filters */

/* new filters */
.ais-CurrentRefinements-list {
    list-style: none;
    display: flex;
    flex-wrap: wrap;
}
.ais-CurrentRefinements-delete {
    position: absolute;
    top: 4px;
    right: 12px;
}
.ais-CurrentRefinements-delete:hover {
    color: var(--maincolor);
}
.ais-CurrentRefinements-label {
    margin-right: 10px;
}
.ais-SortBy {
    display: inline-block;
}
.ais-Stats {
    display: inline;
}
.breadcrumbs_search {
    display: grid;
    grid-template-columns: auto 1fr;
    gap: var(--gap);
}
.ais-SearchBox-form {
    position: relative;
}
.ais-SearchBox-form input[type="search"] {
    display: block;
    width: 100%;
    line-height: 38px;
    border: 1px solid #d9d9d9;
    border-radius: 5px;
    padding: 0 42px 0 27px;
    margin: 15px 0;
}
.ais-SearchBox-form button[type="submit"] {
    position: absolute;
    top: 4px;
    right: 4px;
    width: 32px;
    height: 32px;
    padding: 2px 0 0 0;
}
.ais-SearchBox-form button[type="submit"] svg {
    width: 20px;
    height: 20px;
}
.ais-SearchBox-form button[type="submit"] svg path {
    stroke: var(--darkgrey);
}
.grid_filter__selected {
    display: flex;
}
.ais-ClearRefinements-button {
    position: relative;
    padding: 0 38px 0 14px;
    margin: 0 10px 0 0;
    font-size: .875em;
    line-height: 30px;
    color: #FFF;
    background: #999;
    border-radius: 5px;
}
.ais-ClearRefinements-button:hover {
    background: var(--maincolor);
}
.ais-ClearRefinements-button::before, .ais-ClearRefinements-button::after {
    content: '';
    position: absolute;
    top: 8px;
    right: 19px;
    width: 1px;
    height: 14px;
    background: #FFF;
    transform: rotate(45deg);
}
.ais-ClearRefinements-button::after {
    transform: rotate(-45deg);
}
.ais-ClearRefinements-button.ais-ClearRefinements-button--disabled,
.ais-CurrentRefinements-label,
.ais-SearchBox-form button[type="reset"]
{
    display: none;
}
/* new filters */