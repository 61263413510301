/* banner 200 */
.banner200_wrapper {
    position: relative;
    /* width: 1800px; */
}
.banner200_root{
    display: flex;
    justify-content: center;
    align-items: center;
}
.banner200_slide {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    /* height: 200px; */
}
.banner200_slide img {
    object-fit: cover;
    height: 100%;
    width: 100%;
}
.banner200__close_add {
    position: absolute;
    top: 19px;
    right: 25px;
    color: #FFF;
    font-size: 0.6875rem;
    line-height: calc(15em / 11);
    text-transform: uppercase;
    padding-right: 15px;
}
.banner200__close_add::before,
.banner200__close_add::after {
    content: '';
    position: absolute;
    top: 7px;
    right: 0;
    width: 9px;
    height: 1px;
    background: #FFF;
    transform: rotate(45deg);
}
.banner200__close_add::after {
    transform: rotate(-45deg);
}
.banner200_wrapper .carousel.carousel-slider{
    max-width: var(--maxwidth);
    margin: auto;
}
/* banner 200 */

/* banner 450 */
.banner450_wrapper {
    position: relative;
    margin-bottom: 10px;
}
.banner450_slide {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    /* height: 400px; */
}
.banner450_slide img {
    object-fit: cover;
    height: 100%;
    width: 100%;
}
.banner450_wrapper .carousel .control-dots {
    margin-bottom: 30px;
}
.banner450_wrapper .carousel.carousel-slider{
    max-width: var(--maxwidth);
    margin: auto;
}
.banner450_wrapper .carousel .slider-wrapper {
    max-width: var(--maxwidth);
}
/* banner 450 */

.carousel .control-dots .dot {
    width: 15px !important;
    height: 4px !important;
    background: #d9d9d9 !important;
    border-radius: 2px !important;
    opacity: 1 !important;
    box-shadow: none !important;
}
.carousel .control-dots .dot.selected {
    background: var(--maincolor) !important;
}