@import url('https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/4.1.5/css/flag-icons.min.css');


.login_form__wrapper {
    display: grid;
    grid-template-columns: 36.5% 1fr;
    background: #FFF;
    min-height: 100vh;
}

.login_form {
    padding: 5vh;
    background: var(--lightgrey);
}

.login_form__link {
    margin: 0 0 0 .5em;
    color: var(--maincolor);
    text-decoration: none;
}

.login_form__logo {
    display: block;
    width: auto;
    margin: 0 0 1.25em 0;
}
.login_form__logo img,
.login_form__logo svg {
    display: block;
    width: auto;
    height: 49px;
}
.login_form__header {
    font-size: 2em;
    line-height: 1.361875em;
    font-weight: 700;
    margin: 0 0 11px 0;
}
.login_form__sign_up_help {
    margin: 0 0 28px 0;
}

.login_form__forget_email {
    margin: 32px 0 0 0;
}

.login_form__lang_selector {
    position: relative;
    margin: 40px 0 0 0;
}
.login_form__lang_selector__selected {
    position: relative;
    width: 200px;
    line-height: 2.8125em;
    border: 1px solid #d9d9d9;
    cursor: pointer;
    padding: 0 1em;
    border-radius: 5px;
}
.login_form__lang_selector__selected::after {
    content: '';
    position: absolute;
    top: 1em;
    right: 1em;
    width: 10px;
    height: 10px;
    color: var(--darkgrey);
    border-bottom: 2px solid;
    border-right: 2px solid;
    transform: rotate(45deg);
}
.login_form__lang_selector__wrapper {
    position: absolute;
    /* top: 2.8125em; */
    bottom: 0;
    left: 0;
    list-style: none;
    background: #f8f8f8;
    border-radius: 5px;
    width: 200px;
    display: none;
    height: max-content;
}
.login_form__lang_selector__wrapper.active {
    display: block;
}
.login_form__lang_selector__wrapper li {
    width: 100%;
    line-height: 2.8125em;
    cursor: pointer;
    border: 1px solid #d9d9d9;
    border-top-color: transparent;
    border-bottom-color: transparent;
    padding: 0 1em;
}
.login_form__lang_selector__wrapper li:first-child {
    border-radius: 5px 5px 0 0;
    border-top-color: #d9d9d9;
}
.login_form__lang_selector__wrapper li:last-child {
    border-radius: 0 0 5px 5px;
    border-bottom-color: #d9d9d9;
    
}
.login_form__lang_selector__wrapper li:hover,
.login_form__lang_selector__wrapper li.active {
    background: #f2f2f2;
    border-top-color: #d9d9d9;
    border-bottom-color: #d9d9d9;
}
.login_form__flag {
    position: relative;
    top: 2px;
    display: inline-block;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    margin: 0 1em 0 0;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.login_form__error__fedback_alert {
    font-size: 0.875em;
    line-height: 2.86em;
    color: #FFF;
    background: var(--maincolor);
    border-radius: 5px;
    padding: 0 20px;
    margin: 0 0 21px 0;
    max-width: max-content;
}
.login_form__error__fedback_alert svg {
    position: relative;
    display: inline-block;
    vertical-align: baseline;
    width: 18px;
    min-height: 18px;
    top: 3px;
    margin: 0 .5em 0 0;
}

.login_form__footer {
    margin: 30px 0 0 0;
}
.login_form__footer a, .like_link {
    text-decoration: underline;
    color: var(--darkgrey);
    cursor: pointer;
    white-space: nowrap;
}
.login_form__footer a:hover, .login_form__footer .like_link:hover {
    color: var(--maincolor);
}

.login_form__banner {
    display: flex;
    align-items: center;
    justify-content: center;
}
.login_form__banner img {
    object-fit: fill;
    width: auto;
    height: 100vh;
}

.login_form__input {
    display: block;
    width: 100%;
    line-height: 3em;
    border-radius: 0.3125em;
    border: 1px solid #d9d9d9;
    padding: 0 1.17em;
    margin: 0 0 42px 0;
}
.login_form__input.email_field {
    max-width: 400px;
}
.wrapprePhoneLogin div .form-control{
    width: inherit !important;
    max-width: 300px;
}
.login_form__input.code_field {
    width: 50%;
}

.login_form__button {
    position: relative;
    line-height: 3.125em;
    color: #FFF;
    background: var(--grey);
    border-radius: .3125em;
    font-weight: 700;
    padding: 0 2.625em;
    min-width: 200px;
}
.login_form__button svg {
    vertical-align: baseline;
    position: relative;
    margin: 0 0 0 .5em;
    top: 5px;
}

.marginbottom20 {
    margin-bottom: 20px;
}

.custom_select__wrapper {
    position: relative;
    line-height: 50px;
    color: #999;
}
.custom_select__selected {
    position: relative;
    background: #FFF;
    border: 1px solid #999;
    padding: 0 33px;
    border-radius: 5px;
    cursor: pointer;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    width: 100%;
    height: 50px;
}

.custom_select__wrapper .clear-button {
  position: absolute;
  right: 0px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  font-size: 22px;
  color: #999;
  display: none;
}

.custom_select__wrapper input[type="text"]:focus ~ .clear-button {
  display: inline-block;
}

.custom_select__wrapper input[type="text"]:hover ~ .clear-button {
  color: #ccc;
}

.custom_select__wrapper input[type="text"] + .clear-button {
  margin-right: 30px;
}

.custom_select__selected::after {
    content: '';
    position: absolute;
    top: 20px;
    right: 22px;
    width: 7px;
    height: 7px;
    border-bottom: 1px solid var(--maincolor);
    border-right: 1px solid var(--maincolor);
    transform: rotate(45deg);
}
.custom_select__list {
    position: absolute;
    top: 50;
    left: 0;
    right: 0;
    list-style: none;
    border: 1px solid #999;
    border-radius: 3px;
    background: #FFF;
    z-index: 10;
    display: none;
}
.custom_select__wrapper.active .custom_select__list {
    display: block;
    /* min-height: min-content; */
    max-height: calc(100vh - 340px);
    /* height: calc(100vh - 330px); */
    overflow-y: auto;
}
.custom_select__list li {
    cursor: pointer;
    padding: 0 33px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}
.custom_select__list li.current {
    color: var(--maincolor);
}
.custom_select__list li:first-child {
    border-radius: 5px 5px 0 0;
}
.custom_select__list li:last-child {
    border-radius: 0 0 5px 5px;
}
.custom_select__list li:hover {
    background: #f2f2f2;
}
.wrapper_buttons_recovere_actions {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.six_digits {
    display: flex;
    flex-wrap: nowrap;
    margin-bottom: 20px;
}
.six_digits__single {
    width: 41px;
    line-height: 49px;
    font-size: 1.25rem;
    border: 1px solid #999;
    background: #FFF;
    text-align: center;
    margin-right: 1rem;
    border-radius: 5px;
    font-weight: 600;
}
.alert-danger {
    background-color: var(--maincolor);
}
.login_form h3{
    font-size: 18px;
    line-height: 1.361875em;
    font-weight: 700;
    margin-bottom: 11px;
}
@media screen and (max-width: 1200px) {
    .login_form h3{
        font-size: 14px !important;
        line-height: 1.361875em;
        font-weight: 700;
    }
    .six_digits__single {
        width: 32px;
        line-height: 40px;
    }
    .login_form .alert_resolved {
        font-size: 12px;
        line-height: 18px;
        font-weight: 500;
        padding: 5px 5px 5px 24px;
        background-position: top 5px left 3px;
    }
    .login_form__lang_selector {
        margin-top: 25px;
    }
    .login_form__footer {
        margin-top: 20px;
    }
}
@media screen and (max-width: 1460px) {
    .wrapper_buttons_recovere_actions {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }
    .wrapper_buttons_recovere_actions > button {
        margin-bottom: 20px;
    }
    .wrapper_buttons_recovere_actions > a {
        margin-left: 0px;
    }
}
@media screen and (max-width: 980px) {
    .login_form {
       font-size: 11px;
       line-height: 1.3em;
    }
    .login_form h3{
        font-size: 13px !important;
        line-height: 1.2em;
        font-weight: 700;
    }
    .login_form .alert_resolved {
        font-size: 11px;
        line-height: 15px;
        font-weight: 500;
        padding: 5px 5px 5px 20px;
        background-position: top 5px left 1px;
    }
    .login_form__forget_email {
        margin-top: 15px;
    }
    .login_form__button {
        padding: 0px 5px !important;
    }
    .login_form__input {
        margin-bottom: 20px;
    }
    .login_form__sign_up_help {
        margin-bottom: 15px;
    }
    .six_digits__single {
        width: 25px;
        line-height: 30px;
    }
    .login_form__lang_selector {
        margin-top: 20px;
    }
    .login_form__footer {
        margin-top: 20px;
    }
}
@media screen and (min-width: 980px) and (max-width: 1280px) {
    .login_form {
       font-size: 12px;
    }
    .login_form .alert_resolved {
        font-size: 12px;
        font-weight: 500;
    }
    .login_form__forget_email {
        margin-top: 20px;
    }
    .login_form__button {
        padding: 0px 10px !important;
    }
    .login_form__input {
        margin-bottom: 30px;
    }
    .login_form__sign_up_help {
        font-size: 11px;
        line-height: 1.3em;
    }
    .login_form h3{
        font-size: 16;
        line-height: 1.361875em;
        font-weight: 700;
    }
}

@media screen and (min-width: 1280px) and (max-width: 1460px) {
    .login_form {
       font-size: 14px;
    }
    .login_form__forget_email{
        margin-top: 20px;
    }
    .login_form h3{
        font-size: 16px;
        line-height: 1.361875em;
        font-weight: 700;
        margin-bottom: 11px;
    }
}

@media screen and (min-width:1400px) {
    .login_form {
       font-size: 15px;
    }
}

@media screen and (min-width:1600px) {
    .login_form {
        padding: 5vh 80px;
    }
}

/* loader */
.loader {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    height: 10px;
    background: linear-gradient(-45deg, #ee7752, #e73c7e, #23a6d5, #23d5ab);
	background-size: 400% 400%;
	animation: gradient 3s ease infinite;
    z-index: 1000;
    transition: var(--transition);
    transform: translateY(-10px);
}
.loader.active {
    transform: translateY(0);
}
@keyframes gradient {
	0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
}
/* loader */