.alert_popup__wrapper {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 9999999999;
    visibility: hidden;
}
.alert_popup__wrapper.active {
    visibility: visible;
}
.alert_popup__overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0,0,0,.5);
    z-index: 1;
    transition: var(--transition);
    opacity: 0;
}
.alert_popup__wrapper.active .alert_popup__overlay {
    opacity: 1;
}
.alert_popup__content {
    position: absolute;
    top: 50%;
    left: calc(50%);
    min-width: 720px;
    background: #FFF;
    box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.4);
    border-radius: 5px;
    padding: 50px 40px 40px 40px;
    /* text-align: center; */
    transform: translate(-50%, -50%) scale(0);
    transition: var(--transition);
    z-index: 2;
}
.alert_popup__wrapper.active .alert_popup__content {
    transform: translate(-50%, -50%) scale(1);
}
.wrapper__button_primary {
display: flex;
justify-content: flex-end;
}
.alert_popup__content__close {
    position: absolute;
    top: 19px;
    right: 22px;
    width: 21px;
    height: 21px;
    border: 1px solid #999;
    border-radius: 50%;
}
.alert_popup__content__close::before, .alert_popup__content__close::after {
    content: '';
    position: absolute;
    top: 4px;
    left: 9px;
    width: 1px;
    height: 11px;
    transform: rotate(45deg);
    background: #999;
}
.alert_popup__content__close::after {
    transform: rotate(-45deg);
}
.alert_popup__content__close:hover {
    border-color: var(--darkgrey);
}
.alert_popup__content__close:hover::before, .alert_popup__content__close:hover::after {
    background: var(--darkgrey);
}
.alert_popup__content__text {
    /* text-align: center; */
    margin-bottom: 30px;
    max-height: calc(100vh - 260px);
    overflow-y: auto;
}
.alert_popup__content__text p{
    padding: 0px 1em 1em 0;
    line-height: 28px;
}
.alert_popup__buttons {
    display: flex;
    justify-content: center;
    padding-left: 0 
}
.alert_popup__buttons .btn:first-child {
    white-space: pre-wrap;
    width: max-content;
    max-width: 275px;
    word-break: break-all;
    line-height: 2rem;
    margin-right: 2rem;
}
.alert_popup__text_align_justify {
    text-align: justify;
}
.alert_popup__text_align_justify ol li {
    margin-left: 2em;
}
.alert_popup__text_align_justify p {
    text-indent: 20px;
}

/* ModalShowRows */
.wrapper_content_show_rows {
    display: block;
     margin: 0 0 25px 25px;
}
.wrapper_content_show_rows div{
    margin: 5px;
}
/* ModalShowRows */