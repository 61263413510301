.breadcrumbs {
    list-style: none;
    display: flex;
    flex-wrap: wrap;
    font-size: 0.875rem;
    line-height: 1.36em;
    margin: 21px 0;
}
.breadcrumbs_on_2_colummns .breadcrumbs {
    margin: 0;
}
.breadcrumbs_on_2_colummns {
    max-width: var(--maxwidth);
    margin: 21px auto;
    padding: 0 var(--gap);
    display: grid;
    grid-template-columns: 1fr auto;
    gap: var(--gap)
}
.breadcrumbs_on_2_colummns .wrapper {
    max-width: none;
    margin: 0;
    padding: 0;
}
.breadcrumbs li {
    position: relative;
    margin: 0 22px;
}
.breadcrumbs li:first-child {
    margin-left: 0;
}
.breadcrumbs li:not(:last-child):after {
    content: '';
    position: absolute;
    top: 8px;
    right: -24px;
    width: 5px;
    height: 5px;
    color: var(--darkgrey);
    border-bottom: 1px solid;
    border-right: 1px solid;
    transform: rotate(-45deg);
}
.breadcrumbs a {
    color: var(--darkgrey);
}
.breadcrumbs a:hover {
    color: var(--maincolor);
}
.breadcrumbs a:hover svg path {
    stroke: var(--maincolor);
}

.cart_steps {
    margin: 0;
    padding: 0;
    display: flex;
    font-size: 0.875rem;
    line-height: 1.36em;
    list-style: none;
    counter-reset: myCounter;
}
.cart_steps li {
    margin: 0 11px;
    padding: 0;
}
.cart_steps li.current {
    font-weight: 700;
}
.cart_steps li::before {
    counter-increment: myCounter;
    content: counter(myCounter);
    display: inline-block;
    border: 1px solid var(--darkgrey);
    border-radius: 50px;
    line-height: 22px;
    width: 24px;
    text-align: center;
    margin-right: 10px;
}
.cart_steps li.current::before {
    background: var(--maincolor);
    border-color: var(--maincolor);
    color: #FFF;
}
.cart_steps li a {
    color: var(--darkgrey);
}
.cart_steps li a:hover {
    color: var(--maincolor);
}