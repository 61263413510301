/* My Shopping Cart */
.more_button {
    display: inline-block;
    position: relative;
    margin: 0 0 0 6px;
}
.more_menu__overlay {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1000;
    visibility: hidden;
}
.more_menu__overlay.active {
    visibility: visible;
}
.more_menu {
    position: absolute;
    top: -52px;
    left: 30px;
    min-width: 200px;
    list-style: none;
    font-size: .875rem;
    font-weight: 400;
    background: #f8f8f8;
    border-radius: 5px;
    border: 1px solid #d9d9d9;
    white-space: nowrap;
    color: #666;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
    transition: var(--transition);
    z-index: 1001;
    visibility: hidden;
    opacity: 0;
    transform: translateX(-30px);
}
.more_menu.active {
    visibility: visible;
    opacity: 1;
    transform: translateX(0);
}
.more_menu button {
    display: block;
    width: 100%;
    padding: 0 20px;
    line-height: 45px;
    cursor: pointer;
    color: #666;
    text-align: justify;
}
.more_menu button:first-child {
    border-radius: 5px 5px 0 0;
}
.more_menu button:last-child {
    border-radius: 0 0 5px 5px;
}
.more_menu button:hover {
    background: #f2f2f2;
}
.more_menu button:not(:last-child) {
    border-bottom: 1px solid #d9d9d9;
}
.myshoppingcart__created {
    color: #999;
    font-size: .875rem;
    line-height: 1.357em;
    margin-bottom: 29px;
}
.myshoppingcart__created span {
    color: var(--darkgrey);
}
.cart_columns {
    position: relative;
    --asidewidth: 24%;
    display: flex;
    justify-content: space-between;
}
.change_columns_view {
    position: absolute;
    top: 0;
    right: 0;
    width: 25px;
    height: 25px;
    background: var(--maincolor);
}
.change_columns_view::after {
    content: '';
    position: absolute;
    top: 9px;
    right: 10px;
    width: 7px;
    height: 7px;
    border-bottom: 2px solid #FFF;
    border-right: 2px solid #FFF;
    transform: rotate(-45deg);
}
.cart_columns.one_column .change_columns_view::after {
    right: 7px;
    transform: rotate(135deg);
}
.cart_aside {
    width: var(--asidewidth);
    position: sticky;
    top: 75px;
    /* max-height: 100vh; */
    /* min-height: auto; */
    /* height: 200px; */
    max-height: calc(100vh - 75px);
}
.cart_columns.one_column .cart_aside {
    display: none;
}
.cart_aside__inner {
    background: #f8f8f8;
    border-radius: 5px;
    padding: 25px;
}
.cart_aside__header {
    font-size: 1.25rem;
    line-height: 1.362em;
    font-weight: 700;
    color: var(--maincolor);
    margin-bottom: 30px;
}
.cart_aside__table {
    display: grid;
    grid-template-columns: 1fr auto;
    margin: 10px 0;
    font-weight: 600;
}
.cart_aside__table>div:last-child {
    color: #999;
}
.cart_aside__table.red>div:last-child {
    color: var(--maincolor);
}
.cart_aside__inner hr {
    margin: 20px 0;
}
.cart_aside__table>div:last-child.cart_aside__table__big_price {
    font-size: 1.5rem;
    line-height: 1.375em;
    color: var(--darkgrey);
}
.checkout_now {
    margin-top: 30px;
}
.cart_content {
    transition: var(--transition);
    width: calc(100% - var(--asidewidth) - var(--gap));
}
.cart_columns.one_column .cart_content {
    width: 100%;
}
.add_product_to_cart {
    margin-top: 10px;
    display: flex;
    padding: 10px 26px;
    background: #f2f2f2;
    border: 1px solid #d9d9d9;
    border-radius: 5px;
}
.add_product_to_cart__wrapper {
    position: relative;
    width: 50%;
}
.add_product_to_cart__submit {
    position: absolute;
    top: 9px;
    right: 17px;
    width: 22px;
    height: 20px;
    background-image: url("data:image/svg+xml,%3Csvg width='21' height='19' viewBox='0 0 21 19' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M9.20115 17.1666C13.6194 17.1666 17.2011 13.5849 17.2011 9.16663C17.2011 4.74835 13.6194 1.16663 9.20115 1.16663C4.78288 1.16663 1.20117 4.74835 1.20117 9.16663C1.20117 13.5849 4.78288 17.1666 9.20115 17.1666Z' stroke='%23606060' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M20.2011 18.1667L15.8511 14.3' stroke='%23606060' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
    background-position: center;
    background-repeat: no-repeat;
}
.plus_product {
    line-height: 40px;
    width: 40px;
    font-size: 30px;
    color: var(--maincolor);
    margin-right: 10px;
}
.grid_prod_img {
    display: flex;
    width: 46px;
    height: 76px;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}
.grid_prod_img img {
    object-fit: contain;
    max-width: 46px;
    min-width: 46px;
    height: 46px;
}
.aggrid_plusminus_group {
    display: grid;
    grid-template-columns: 40px 55px 40px;
}
.apg_input {
    line-height: 38px;
    border: 0 none;
    border-top: 1px solid #d9d9d9;
    border-bottom: 1px solid #d9d9d9;
    text-align: center;
}
.apg_button_plus, .apg_button_minus, .apg_button_delete {
    line-height: 38px;
    border: 1px solid #d9d9d9;
    text-align: center;
    color: var(--darkgrey);
    border-radius: 5px 0 0 5px;
    background: #FFF;
    font-size: 1.5rem;
}
.apg_button_plus {
    border-radius: 0 5px 5px 0;
    color: #FFF;
    background: #00B707;
}
.apg_button_delete svg {
    position: relative;
    top: 11px;
}
.hide_delete .apg_button_delete {
    display: none;
}
.show_delete .apg_button_minus {
    display: none;
}
.aggrid_status__wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
    height: 100%;
}
.aggrid_status {
    width: 13px;
    height: 13px;
    border-radius: 50%;
}
.aggrid_status_text {
}
.aggrid_status.outofstock {
    background: var(--maincolor);
}
.aggrid_status_text.outofstock {
    color: var(--maincolor);
}
.aggrid_status.low {
    background: #FC6738;
}
.aggrid_status_text.low {
    color: #FC6738;
}
.aggrid_status.instock {
    background: #00B707;
}
.aggrid_status_text.instock {
    color: #00B707;
}
.aggrid_discount {
    display: inline-block;
    line-height: 30px;
    font-size: 1.25rem;
    color: #000;
    font-weight: 700;
    background: #FFD600;
    border-radius: 5px;
    padding: 0 5px;
}
.aggrid_minus, .aggrid_plus {
    width: 40px;
    height: 40px;
    border-radius: 5px 0 0 5px;
    border: 1px solid #d9d9d9;
    background: #FFF;
}
.aggrid_plus {
    border-radius: 0 5px 5px 0;
    color: #FFF;
    background-color: #00B707;
}
.ag-cell {
    display: flex !important;
    align-items: center;
}
.ag-cell.noflex {
    display: inline-block !important;
}
.ag-cell-focus, .ag-cell {
    border: none !important;
}
.ag_prodname {
    display: block;
    line-height: 1.362em;
    /* height: 2.724em; */
    overflow-y: hidden;
    white-space: normal;
}
.ag_prodhistory {
    font-size: .875rem;
    line-height: 18px;
    color: var(--darkgrey);
    margin: 8px 0 0 0;
}
.ag_prodhistory * {
    pointer-events: none;
}
.ag_prodhistory:hover svg path {
    stroke: var(--maincolor);
}
.ag_prodhistory__refresh {
    margin-right: 1em;
}
.ag_prodhistory__list {
    margin-left: 1em;
}
.ag_prodhistory:hover {
    color: var(--maincolor);
}
.cart_product__actions {
    padding: 6px 15px;
    display: grid;
    grid-template-columns: auto 1fr auto;
    gap: 15px;
    background: #F2F2F2;
    color: #999;
    border-radius: 5px;
}
.cart_product__actions__label {
    line-height: 50px;
}
.aggrid_history {
    font-size: .875rem;
    line-height: 1.357em;
    color: #999;
    margin: 6px 0;
}
.aggrid_history svg {
    margin-right: 10px;
}

.add_product_insert {
    display: flex;
    padding: 12px 17px 13px 17px;
    gap: 15px;
    align-items: flex-end;
    justify-content: flex-end;
}

.rename_cart__wrapper {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    visibility: hidden;
    z-index: 1000;
}
.rename_cart__wrapper.active {
    visibility: visible;
}
.rename_cart__overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0,0,0,0.5);
    transition: var(--transition);
    z-index: 1;
    opacity: 0;
}
.rename_cart__wrapper.active .rename_cart__overlay {
    opacity: 1;
}
.rename_cart__popup {
    position: absolute;
    top: 50%;
    left: calc(50%);
    min-width: 450px;
    max-width: 550px;
    background: #FFF;
    box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.4);
    border-radius: 5px;
    padding: 50px 40px 40px 40px;
    text-align: center;
    transform: translate(-50%, -50%) scale(0);
    transition: var(--transition);
    z-index: 2;
}
.rename_cart__wrapper.active .rename_cart__popup {
    transform: translate(-50%, -50%) scale(1);
}
.rename_cart__popup__title {
    font-size: 20px;
    font-weight: 700;
    margin-bottom: 30px;
}
.rename_cart__popup__buttons {
    margin-top: 30px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
}
.rename_cart__popup__close {
    position: absolute;
    top: 19px;
    right: 22px;
    width: 21px;
    height: 21px;
    border: 1px solid #999;
    border-radius: 50%;
}
.rename_cart__popup__close::before,
.rename_cart__popup__close::after {
    content: '';
    position: absolute;
    top: 4px;
    left: 9px;
    width: 1px;
    height: 11px;
    transform: rotate(45deg);
    background: #999;
}
.rename_cart__popup__close::after {
    transform: rotate(-45deg);
}
.rename_cart__popup__close:hover {
    border-color: var(--darkgrey);
}
.rename_cart__popup__close:hover::before, .alert_popup__content__close:hover::after {
    background: var(--darkgrey);
}
.wrapper__custom_group_row {
    display: grid;
    grid-template-columns: 460px 160px 150px; 
}
.wrapper__custom_group_row > span {
    min-width: 300px;
}
.shopping_cart_summary {
    font-weight: 700
}
.custom_style_cell__not_show {
    display: none !important;
    color: #fff;
    opacity: 0;
}
.line_heigh_inherit{
    line-height: inherit;
}
/* My Shopping Cart */

/* Checkout */
.checkout_columns {
    --asidewidth: 24%;
    display: flex;
    justify-content: space-between;
}
.checkout_columns__content {
    width: calc(100% - var(--asidewidth) - var(--gap));
}
.checkout_accorderon {
    margin-bottom: 35px;
}
.checkout_accorderon__title {
    position: relative;
    cursor: pointer;
    padding: 0 68px;
    line-height: 50px;
    background: #d9d9d9;
    margin-bottom: 19px;
    border-radius: 5px;
    font-weight: 700;
}
.checkout_accorderon__title::before {
    content: '';
    position: absolute;
    top: 22px;
    left: 29px;
    width: 12px;
    height: 12px;
    border-right: 2px solid var(--maincolor);
    border-bottom: 2px solid var(--maincolor);
    transform: rotate(225deg);
    transition: var(--transition);
}
.checkout_accorderon.active .checkout_accorderon__title::before {
    transform: translateY(-6px) rotate(45deg);
}
.checkout_accorderon:first-child .checkout_accorderon__title::before {
    content: none;
}
.checkout_accorderon__content {
    padding: 0;
    background: transparent;
    border-radius: 0;
    display: none;
}
.checkout_accorderon__content.standard {
    grid-template-columns: 1fr 1fr;
    gap: 20px;
}
.checkout_accorderon__content_delivery {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 15px;
}
.checkout_accorderon__content.warehouse {
    grid-template-columns: 1fr;
}
.checkout_accorderon__content.warehouse .checkout_accorderon__content__inner:last-child {
    display: none;
}
.checkout_accorderon__content__inner {
    padding: 37px 42px;
    background: #f8f8f8;
    border-radius: 5px;
}
.checkout_accorderon.active .checkout_accorderon__content {
    display: grid;
}
.checkout_accorderon__content__title {
    font-weight: 700;
}
.checkout_accorderon__content__unit {
    margin: 20px 0;
}
.checkout_accorderon__content__unit dt {
    font-weight: 600;
    margin-bottom: 10px;
}
.checkout_accorderon__content__delivery {
    position: relative;
    background: #f8f8f8;
    border-radius: 5px;
    margin-bottom: 19px;
    padding: 26px 68px;
}
.checkout_accorderon__content__delivery__change_pickup {
    font-weight: 600;
    margin: 42px 0 14px 0;
}
.checkout_accorderon__content__delivery.active {
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.15);
}
.checkout_accorderon__content__delivery__label {
    display: block;
    font-weight: 600;
    margin-bottom: 10px;
    cursor: pointer;
}
.checkout_accorderon__content__delivery__input {
    position: absolute;
    top: 32px;
    left: 37px;
    accent-color: var(--maincolor);
}
.checkout_accorderon__content__delivery__price {
    position: absolute;
    top: 26px;
    right: 68px;
    font-weight: 700;
}
.checkout_delivery__monday_to_friday {
    margin-bottom: 17px;
}
.checkout_accorderon__content__delivery__info,
.checkout_accorderon__content__delivery__info_accordeon {
    display: flex;
    width: max-content;
    padding: 0 14px 0 18px;
    background: #FFF;
    color: var(--darkgrey);
    border: 1px solid var(--darkgrey);
    line-height: 50px;
    border-radius: 5px;
    margin: 15px 0 30px 0;
}
.checkout_accorderon__content__delivery__info_accordeon {
    margin: 14px 0 10px 0;
}
.checkout_accorderon__content__delivery__info svg,
.checkout_accorderon__content__delivery__info_accordeon svg {
    position: relative;
    top: 14px;
    margin-right: 21px;
}
.checkout_accorderon__content__delivery__info strong,
.checkout_accorderon__content__delivery__info_accordeon strong {
    font-weight: 600;
}
.checkout_accorderon__supplier_info {
    display: flex;
    padding: 12px 17px;
    justify-content: space-between;
    align-items: flex-end;
    border-radius: 5px;
    border: 1px solid var(--400, #D9D9D9);
    margin-bottom: 10px;
}
.checkout_accorderon__supplier_info div {
    display: flex;
    gap: 20px;
}
.checkout_accorderon__content__delivery__grey_info {
    position: relative;
    font-size: .875rem;
    line-height: 1.357em;
    color: #999;
}
.checkout_accorderon__content__delivery__grey_info svg {
    position: absolute;
    top: 0;
    left: -28px;
}
.checkout_accorderon__content__delivery__select {
    position: relative;
    width: 100%;
    color: #999;
    line-height: 3rem;
    height: 50px;
    border: 1px solid #d9d9d9;
    border-radius: 5px;
    padding: 0 56px 0 28px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background-image: url("data:image/svg+xml,%3Csvg fill='none' height='18' viewBox='0 0 18 18' width='18' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='m4.5 6.75 4.5 4.5 4.5-4.5' stroke='%239f0027' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.5'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-position: center right 19px;
    margin-bottom: 29px;
}
.checkout_columns__aside {
    width: var(--asidewidth);
    position: sticky;
    top: 75px;
    max-height: calc(100vh - 75px);
    overflow-y: auto;
}
.checkout_columns__aside__unit {
    padding: 25px;
    background: #f8f8f8;
    border-radius: 5px;
    margin-bottom: 14px;
}
.checkout_columns__aside__unit:last-child {
    margin-bottom: 0;
}
.checkout_columns__aside__unit__header {
    color: var(--maincolor);
    font-weight: 700;
    font-size: 1.25rem;
    line-height: 1.362em;
    margin-bottom: 15px;
}
.checkout_columns__aside hr {
    margin: 16px 0;
}
.btn-modify-cart,
.btn-proceed-payment,
.submit_return_button,
.final_feedback_submit {
    position: relative;
    min-height: 50px;
    margin-top: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 120px;
}
.btn-modify-cart svg,
.btn-proceed-payment svg {
    vertical-align: middle;
}
.btn-proceed-payment .waiting {
    display: flex !important;
    justify-content: center;
}
.submit_loader:disabled {
    cursor: not-allowed;
}
.submit_loader.waiting .waiting,
.submit_loader.loading .loading {
    display: block;
}
.submit_loader.loading .waiting,
.submit_loader.waiting .loading {
    display: none;
}
.submit_loader .spinner_ring {
    position: absolute;
}
.payment_method {
    list-style: none;
    padding: 39px 0 20px 100px;
}
.payment_method li {
    padding: 6px 0;
}
.payment_method input[type="radio"] {
    margin-right: 24px;
}
.additional_notes__columns {
    display: grid;
    grid-template-columns: 2fr 5fr;
    gap: 40px;
}
.checkout_aside_label input {
    margin-right: 15px;
}
.checkout_aside_label a {
    text-decoration: underline;
    color: var(--darkgrey);
}
.checkout_aside_label a:hover {
    color: var(--maincolor);
}
.please_accept_terms_of_sale {
    position: relative;
    padding: 5px;
    color: #FFF;
    background: var(--maincolor);
    text-align: center;
    margin: 10px 0 15px 0;
}
.please_accept_terms_of_sale::after {
    content: '';
    position: absolute;
    top: 100%;
    left: 0;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 10px 10px 0 0;
    border-color: var(--maincolor) transparent transparent transparent;
}
.term_of_sale {
    text-decoration: underline;
    cursor: pointer;
}
.term_of_sale:hover {
    color: var(--maincolor);
}
/* Checkout */

/* My Orders */
.orders_obs {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}
.orders_obs::after {
    content: attr(data-title);
    position: absolute;
    top: -10px;
    right: -20px;
    width: 242px;
    background: #FFF;
    font-size: .875rem;
    line-height: 1.357em;
    color: #727272;
    padding: 14px 14px 15px 18px;
    border: 1px solid #d9d9d9;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    white-space: normal;
    transition: var(--transition);
    visibility: hidden;
    opacity: 0;
    transform: translateY(5px);
    z-index: 2;
}
.orders_obs:hover::after {
    visibility: visible;
    opacity: 1;
    transform: translateY(0);
}
.orders_duplicate {
    display: flex;
    width: 32px;
    justify-content: center;
    align-items: center;
    height: 100%;
    margin: 0 auto;
}
.orders_status {
    text-align: center;
}
.orders_status.pending {
    color: #FC6738;
}
.orders_status.sent {
    color: #006834;
}
.orders_status.cancelled {
    color: var(--maincolor);
}
.orders_status.approved, .orders_status.processing {
    color: #00B707;
}
.orders_link {
    color: var(--darkgrey);
    text-decoration: underline;
}
.orders_link:hover {
    color: var(--maincolor);
}
/* My Orders */

/* Order Details */
.order_details__two_columns {
    display: grid;
    grid-template-columns: 1fr 342px;
    gap: 0 15px;
}
.order_details__summary {
    padding: 39px 40px 37px 41px;
    background: #f8f8f8;
    border: 1px solid #d9d9d9;
    border-radius: 5px;
    margin-bottom: 22px;
}
.order_details__aside__header {
    font-size: 1.25rem;
    line-height: 1.362em;
    font-weight: 700;
    color: var(--maincolor);
    margin-bottom: 21px;
}
.btn-dublicate_order {
    margin-top: 47px;
}
.order_details__summary hr {
    margin: 20px 0;
}
.order_details__status {
   
    border: 1px solid #d9d9d9;
    border-radius: 5px;
}
.order_details__status>div:first-child{
  padding: 39px 40px 20px 41px;
}
.order_details__status .cart_aside__table>div:last-child {
    color: var(--darkgrey);
    font-weight: 400;
}
.order_details__status .cart_aside__table>div.processing:last-child {
    color: #00B707;
}
.order_details__status__buttons {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr 1fr;
    gap: 14px;
    margin: 0 10px;
}
.order_details__status__buttons button {
    width: 321px;
    padding: 0 1em;
}
.wrapper__title_carts{
    display: flex;
     justify-content: space-between;
      align-items: center;
       margin-bottom: 10px;
}
/* Order Details */