.date_picker {
    margin-left: 3px;
}
.date_picker_custom_input {
    cursor: pointer;
    margin-top: 2px;
}
.date_picker_disabled{
    cursor: default;
}
.date_picker_custom_input button {
    color: var(--darkgrey);
    margin: 12px 12px 0 6px;
}
.date_picker_disabled button {
    color: var(--grey);
    margin: 12px 12px 0 6px;
    cursor: default;
}
.date_picker div div input {
    border: 0;
    color: var(--darkgrey);
    width: 86px;
    margin-right: 19px;
    padding: 1px 2px;
}
.date_picker div div div .react-datepicker {
    /*background: red;*/
}
.date_picker_custom_input svg{
    margin-right: 5px ;
}